body {
  margin: 0;
  padding: 0;
  font-family: Mulish, sans-serif;
  line-height: 16px;
  overflow-x: hidden;
  font-size: 14px;
}

a,
body,
h1,
h2,
h3,
h4,
h5,
li,
p {
  font-family: Mulish, sans-serif;
}

h2,
h3 {
  margin-bottom: 20px;
  padding-top: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
  color: #f04f38;
}

b,
strong {
  font-weight: 700;
}

ul {
  list-style: none;
  padding: 0;
}
p {
  font-size: 14px;
}
a {
  color: #212529;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 14px;
}

a:focus,
button:focus {
  outline: none;
}

a:hover {
  text-decoration: none;
}
.checkout a:hover { 
    text-decoration: underline;
}

.videoDetailDescription a{
  text-decoration: none;
}

.videoDetailDescription a:hover{
  text-decoration: underline;
}

.cart__contentUsage--container a:hover {
  text-decoration: underline;
}

.hidden {
  display: none !important;
  visibility: hidden !important;
}

.jukin-bt,
a.jukin-bt {
  font-weight: 900;
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
  height: 42px;
  border-radius: 5px;
  background-color: #f04f38;
  box-shadow: 1px 2px 8px rgba(70, 70, 70, 0.15);
  border: 1px solid #c22c0c;
  min-width: 150px;
}

.clearer {
  margin: 15px 0;
}

input {
  outline: none;
}

.align-center {
  text-align: center !important;
}

.align-right {
  text-align: right !important;
}

.noLeft {
  padding-left: 0;
}

.input-group {
  flex-wrap: nowrap;
}

.noRight {
  padding-right: 0;
}

.bold {
  font-weight: 700 !important;
}

iframe {
  margin: 0;
  padding: 0;
  border: 0;
}

.popover {
  background: rgba(0, 0, 0, 1);
  color: #fff;
  font-size: 12px;
  border: none;
  border-radius: 0;
}

.popover-body {
  color: #fff;
  font-family: "Mulish", sans-serif;
  text-align: center;
  font-size: 12px;
}

.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: rgba(0, 0, 0, 1);
}

.terms-modal {
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
}

.ReactModal__Overlay {
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.9) !important;
}

@-webkit-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-ms-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes uil-ring-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.uil-ring-css {
  background: none;
  position: relative;
  width: 200px;
  height: 200px;
  margin: auto;
}
.uil-ring-css > div {
  position: absolute;
  display: block;
  width: 160px;
  height: 160px;
  top: 20px;
  left: 20px;
  border-radius: 80px;
  box-shadow: 0 6px 0 0 #00C3CB;
  -ms-animation: uil-ring-anim 1s linear infinite;
  -moz-animation: uil-ring-anim 1s linear infinite;
  -webkit-animation: uil-ring-anim 1s linear infinite;
  -o-animation: uil-ring-anim 1s linear infinite;
  animation: uil-ring-anim 1s linear infinite;
}
.video-list-popup-item .uil-ring-css {
  position: absolute;
  top: 1px;
  right: 22px;
  height: 18px;
  width: 18px;
}
.shareUrlContainer .uil-ring-css {
  height: 46px;
  margin: auto;
  top: -10px;
  left: 0;
}

#chat-widget-container {
  opacity: 1 !important;
  visibility: visible !important;
  z-index: 10000 !important;
}
.grecaptcha-badge {
  z-index: 9999;
}

.cc-window {
  padding: 1em 15% !important;
  flex: none;
}
span.cc-message {
  font-size: 14px;
  font-family: Mulish, sans-serif;
  flex: none !important;
  margin-right: 3em !important;
  line-height: 130%;
}
a.cc-link {
  font-family: Mulish, sans-serif;
  color: #008183 !important;
  text-decoration: none;
  display: block;
  width: 235px;
  transition: all 0.6s ease 0s;
}
a.cc-deny {
  display: none;
}
a.cc-allow {
  font-family: Mulish, sans-serif;
  border: 0;
  color: #fff;
  text-transform: uppercase;
  transition: all 0.6s ease 0s;
}
a.cc-allow:hover {
  color: #008183;
}

.cc-revoke {
  font-family: Mulish, sans-serif;
  transition: all 0.6s ease 0s;
}
.cc-revoke.cc-bottom:hover {
  color: #00C3CB;
  text-decoration: none;
}
@media screen and (max-width: 1200px) {
  .cc-window {
    padding: 1em 1.8em !important;
  }
}

@media screen and (max-width: 991px) {
  .cc-window {
    flex-direction: column;
    align-items: start;
  }
  .cc-compliance {
    position: absolute;
    right: 0.8em;
    bottom: 3px;
  }
  a.cc-allow {
    font-size: 13px;
  }
}
@media screen and (max-width: 767px) {
  .grecaptcha-badge {
    bottom: 100px !important;
  }
  .cc-revoke.cc-bottom {
    right: 3em;
    left: auto;
  }
}
